import { useState } from 'react';
import Cookie from 'js-cookie';
import styled from '@emotion/styled';

import { ONE_HOUR_AS_FRACTION_OF_DAY } from '@app/services/date-utils';

import { color as themeColors } from '@app/themes/mubi-theme';

import UserStateBanner from '@app/components/banners/UserStateBanner';
import useGetLocaleMismatchBannerContent from '@app/components/layout/useGetLocaleMismatchBannerContent';

const DISMISS_COOKIE_NAME = 'locale-notice';

const DISMISS_LENGTH = ONE_HOUR_AS_FRACTION_OF_DAY;

const LocaleNoticeContainer = ({
  withFixedPosition = false,
}: {
  withFixedPosition?: boolean;
}) => {
  const [isDismissed, setIsDismissed] = useState<string>(
    Cookie.get(DISMISS_COOKIE_NAME),
  );
  const [message, linkText, linkUrl] = useGetLocaleMismatchBannerContent();

  if (!isDismissed && message && linkText && linkUrl) {
    const banner = (
      <UserStateBanner
        messageComponent={
          <>
            <BannerMessage>{message}</BannerMessage>
            <BannerCTA href={linkUrl}>{linkText}</BannerCTA>
          </>
        }
        dismissCookieName={DISMISS_COOKIE_NAME}
        dismissLengthInDays={DISMISS_LENGTH}
        backgroundColor={themeColors.lightBlue}
        onDismiss={() => setIsDismissed('true')}
      />
    );
    if (withFixedPosition) {
      return (
        <LocaleNoticeSpace>
          <LocaleNoticeFixedPosition>{banner}</LocaleNoticeFixedPosition>
        </LocaleNoticeSpace>
      );
    }
    return banner;
  }
  return null;
};

const LocaleNoticeSpace = styled.div`
  height: 62px;
  width: 100%;
`;

const LocaleNoticeFixedPosition = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`;

const BannerMessage = styled.span`
  margin-right: 8px;
  width: 100%;
  display: block;
  font-weight: normal;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: inline;
  }
`;

const BannerCTA = styled.a`
  font-weight: 500;
`;

export default LocaleNoticeContainer;
